import styled from "styled-components";
import { PropsWithChildren, useEffect, useState } from "react";
import { DateTime } from "luxon";

// make the timercontainer span the entire width of the page
const TimerContainer = styled.section`
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0px;
  z-index: 5;
  overflow: hidden;
  padding-top:10px;
  padding-bottom:0px;
  background-color: #0060df; 

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
  }
`;


const TimerContainerLanding = styled.section`
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0px;
  z-index: 5;
  overflow: hidden;
  padding-top:10px;
  padding-bottom:0px;
  background-color: #0060df; 

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display:none;

  }
  & > * {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
  }
`;


const TitleImage = styled.img`
  position: absolute;
  height: 100px;
  width: 100px;
  flex-shrink: 0;
  top: 0;
  left: -100px;
  filter: invert(1);
  @media (max-width: 865px) {
    display: none;
  }
`;

const TimerImage = styled.img`
  @media (min-width: 866px) {
    display: none;
  }
  position: absolute;
  height: 100px;
  width: 100px;
  flex-shrink: 0;
  top: -35;
  left: -10px;
  filter: invert(1);
  @media (max-width: 425px) {
    display: none;
  }
`;

const StyledTitle = styled.h1`
  position: relative;
  font-family: "Oswald";
  font-size: 32px;
  font-weight: 900;
  margin-top: 5px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  max-width: 900px;
  text-shadow: 4px 4px 0px #1e1e1e;
  
  @media (max-width: 768px) {
    margin-top: 0px;
    font-size: 28px;
  }
`;

const TimerBody = styled.h3`
  font-family: 'Oswald';
  color: #f5f5f5;
  font-size: 16px;
  font-weight: 600;
  
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
`;

const MiniBody = styled.div`
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  font-size: 18px;
  padding-bottom: 10px;
  text-align: center;
  font-style: italic;
`;

const MiniBodyBold = styled.div`
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  font-size: 18px;
  font-weight:600;
  padding-bottom: 10px;
  text-align: center;
`;

const MiniTitle = styled.div`
  position: relative;
  font-family: "Oswald";
  font-size: 30px;
  font-weight: 900;
  margin-top: 5px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  max-width: 900px;
  text-shadow: 4px 4px 0px #1e1e1e;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const MiniImage = styled.img`
  position: absolute;
  flex-shrink: 0;
  top: 0;
  left: -50px;
  height: 50px;
  width: 50px;
  flex-shrink: 0;
  filter: invert(1);
  @media (max-width: 510px) {
    display: none;
  }
`;

const TimerCol = styled.div``;
const Timers = styled.div`
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  justify-items: center;

  ${TimerCol} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400
    }
  }
`;

const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  background: #dfb140;
  border-radius: 6px;
  padding-top: 10px;
  display: block;

  color: #1e1e1e;
  font-family: 'Lato';
  font-size: 28px;
  font-feature-settings: "tnum";
  font-size: 28px;
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  padding-left: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 8px;
`;

const HiddenWhenBig = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const getTimeLeft = (targetTime: Date) => {
  const now = new Date();
  const difference = targetTime.getTime() - now.getTime();
  if (difference <= 0) {
    return null;
  }

  const timeLeft = {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  };

  return timeLeft;
};

const BLACK_FRIDAY_END = DateTime.fromObject({ year: 2024, month: 1, day: 9, hour: 23, minute: 59 }, { zone: "America/Los_Angeles" });

const formatTimeUnit = (unit: number) => unit.toString().padStart(2, '0');

type Props = PropsWithChildren<{mini?: boolean, extra?: string}>;
const BlackFridayTimer = (props: Props) => {
  const enabled = true;
  const endTime = BLACK_FRIDAY_END.toJSDate();
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(endTime));

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedTimeLeft = getTimeLeft(endTime);
      if (updatedTimeLeft) {
        setTimeLeft(updatedTimeLeft);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime, timeLeft]);

  if (!enabled) {
    return <></>;
  }

  if (!timeLeft) {
    return <></>;
  }

  if (props.mini) {
    return (<TimerContainer>
      <>
      <MiniBodyBold>
      Get Up To 30% Off Your All-New BWS+ Program
      </MiniBodyBold>
      <MiniBody>
      Risk-Free 14 Day Free Trial
</MiniBody>
      </>
    </TimerContainer>);
  }

  return (
    <TimerContainerLanding>
      <StyledTitle>BRAND NEW BWS+ LAUNCH SPECIAL
</StyledTitle>
      <TimerBody>
      Get Up To 30% Off BWS+ (Limited Time Only)
        <br />
        Offer Ends In:


        <Timers>
          <TimerImage alt="black friday" src="https://builtwithscience.com/wp-content/uploads/2023/12/png-transparent-black-party-hat-party-computer-icons-wedding-birthday-confetti-white-holidays-service-removebg-preview.png"/>
          <TimerCol><Card>{formatTimeUnit(timeLeft.days)}</Card><span>Days</span></TimerCol>
          <TimerCol><Card>{formatTimeUnit(timeLeft.hours)}</Card><span>Hours</span></TimerCol>
          <TimerCol><Card>{formatTimeUnit(timeLeft.minutes)}</Card><span>Minutes</span></TimerCol>
          <TimerCol><Card>{formatTimeUnit(timeLeft.seconds)}</Card><span>Seconds</span></TimerCol>
        </Timers>
      </TimerBody>
      <HiddenWhenBig>
        {props.children}
      </HiddenWhenBig>
    </TimerContainerLanding>
  );
};

export {
    BlackFridayTimer,
};
