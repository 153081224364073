const followingusQuestion = {
  name: 'followingus',
  title: 'How long have you been following Jeremy or Built With Science?',
  type: 'radiogroup',
  choices: [
    {
      value: 'Never',
      text: 'Never',
    },
    {
      value: 'Just discovered us',
      text: 'Just discovered us',
    },
    {
      value: 'Less than 6 months',
      text: 'Less than 6 months',
    },
    {
      value: '6 months to 1 year',
      text: '6 months to 1 year',
    },
    {
      value: '1 to 2 years',
      text: '1 to 2 years',
    },
    {
      value: '2+ years',
      text: '2+ years',
    },
    
  ],
  isRequired: true,
};

export {
  followingusQuestion,
};