import _ from "lodash";


import { Experience } from "../../quiz/experience";
import { Gender } from "../../quiz/gender";
import { Goals } from "../../quiz/goals";
import { User } from "../../types";
import { AppProgramLevelProps } from "./AppProgramLevel.types";
import { CDN_ROOT } from "../images";

type LandingPageConfig = {
  gender: Gender;
  goal: ReadonlyArray<Goals>;
  experience: Experience;
  name: string;
  listName: string;
};

type PlanConfig = {
  productId: string;
  name: string;
  image: string;
  buyLink: string;
};

type ProgramLevelConfig = {
  base: PlanConfig;
  premium: PlanConfig;
  elite: PlanConfig;
} & LandingPageConfig;

function getConfigForUser<T extends LandingPageConfig>(
  user: User,
  config: ReadonlyArray<T>
) {
  return _.find(
    config,
    (item) =>
      item.gender === user.gender &&
      _.includes(item.goal, user.goals) &&
      item.experience === user.experience
  );
}



type ProgramNames = (typeof APP_PROGRAM_CONFIG)[number]["name"];
// type ProgramDurationNames = typeof PROGRAM_DURATION_CONFIG[number]['name'];

function getProgramNameForUser(user: User): ProgramNames {
  const configItem = getConfigForUser(user, APP_PROGRAM_CONFIG);
  if (configItem) {
    return configItem.name;
  }

  throw new Error("No configuration found for the given user");
}



const appBaseFeatures = [
  "Step-By-Step Training & Nutrition Plan",
  "Gym & Home Workouts",
  "**100** Exercise Tutorial Videos",
  "**100** Fat Loss Meals",
] as const;


const appBaseFeaturesShred = [
  "Step-By-Step Training & Nutrition Plan",
  "Gym & Home Workouts",
  "**100** Exercise Tutorial Videos",
  "**100** Fat Loss Meals",
] as const;

const appPremiumFeatures = [
  "Highly Personalized Training & Nutrition Program",
  "Gym & Home Workouts",
  "Private Facebook Community",
  "**200+** Exercise Tutorial Videos",
  "**200+** Fat Loss Meals",
  "30-Minute Time Saving Workouts",
] as const;

const appPremiumFeaturesShred = [
  "Highly Personalized Training & Nutrition Program",
  "Gym & Home Workouts",
  "Private Facebook Community",
  "**200+** Exercise Tutorial Videos",
  "**200+** Fat Loss Meals",
  "30-Minute Time Saving Workouts",
] as const;

const appPremiumBonusFeatures = [
  "Smart Coaching Every Single Workout",
  "Personalized Nutrition Guidance",
  "Effortless Nutrition Tracking With Barcode & AI Scanner",
  "Daily Guidance & Habit Building",
  "24/7 Support",
  "Advanced Progress Tracking & Analytics",
] as const;

const appEliteFeatures = [
  "Highly Personalized Training & Nutrition Program",
  "Gym & Home Workouts",
  "Private Facebook Community",
  "**200+** Exercise Tutorial Videos",
  "**200+** Fat Loss Meals",
  "30-Minute Time Saving Workouts",
] as const;

const appEliteFeaturesShred = [
  "Highly Personalized Training & Nutrition Program",
  "Gym & Home Workouts",
  "Private Facebook Community",
  "**200+** Exercise Tutorial Videos",
  "**200+** Fat Loss Meals",
  "30-Minute Time Saving Workouts",
] as const;

const appEliteBonusFeatures = [
  "Smart Coaching Every Single Workout",
  "Personalized Nutrition Guidance",
  "Effortless Nutrition Tracking With Barcode & AI Scanner",
  "Daily Guidance & Habit Building",
  "24/7 Support",
  "Advanced Progress Tracking & Analytics",
] as const;

const appEliteExtraFeatures = [
  "**48** Weekly Meal Plans",
  "Budget Friendly Meal Prep Maker",
  "Automatic Grocery List Generator",
  "5-Minute-Meal Recipes",
  "30-Minute Weekly Meal Prep Guide",
  "Mobility & Posture Routine",
] as const;



const appIntroFeaturesBasiconly =[
  "Web-Based Program"
] as const;

const APP_PROGRAM_CONFIG = [
  {
    gender: Gender.Male,
    experience: Experience.Beginner,
    goal: [Goals.BuildMuscle],
    name: 'Male Beginner Build',
    listName: 'Beginner build program plus',
    base: {
      productId: "8159849545976",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735205273848:1?channel=buy_button`,
      name: "BWS Basic Plan - Build",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-build.png`,
    },
    premium: {
      productId: "8159851544824",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNrqJnrTlLWSIiB4TRe2xF/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Build",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159855902968",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNnLJnrTlLWSIiHLIbMflm/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Build",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Beginner,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Female Beginner Lean',
    listName: 'Beginner lean program plus',
    base: {
      productId: "8159927697656",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735212089592:1?channel=buy_button`,
      name: "BWS Basic Plan - Lean",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-lean.png`,
    },
    premium: {
      productId: "8159942967544",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNrqJnrTlLWSIiB4TRe2xF/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Lean",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
    },
    elite: {
      productId: "8159945556216",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNnLJnrTlLWSIiHLIbMflm/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Lean",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
    },
  },
  {
    gender: Gender.Female,
    goal: [Goals.BuildMuscle],
    experience: Experience.Beginner,
    name: "Female Beginner Strong",
    listName: "Beginner strong program plus",
    base: {
      name: "BWS Basic Plan - Strong",
      productId: "8160113131768",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-strong.png`,
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735218413816:1?channel=buy_button`,
    },
    premium: {
      name: "BWS+ Premium Plan - Strong",
      productId: "8018561990904",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNrqJnrTlLWSIiB4TRe2xF/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
    },
    elite: {
      name: "BWS+ Elite Plan - Strong",
      productId: "8018562121976",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNnLJnrTlLWSIiHLIbMflm/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Beginner,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Male Beginner Shred',
    listName: 'Beginner shred program plus',
    base: {
      productId: "8159897092344",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735185449208:1?channel=buy_button`,
      name: "BWS Basic Plan - Shred",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-shred.png`,
    },
    premium: {
      productId: "8159898042616",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNrqJnrTlLWSIiB4TRe2xF/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Shred",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159898697976",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNnLJnrTlLWSIiHLIbMflm/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Shred",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Intermediate,
    goal: [Goals.BuildMuscle],
    name: 'Male Intermediate Build',
    listName: 'Intermediate build program plus',
    base: {
      productId: "8159889096952",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735193116920:1?channel=buy_button`,
      name: "BWS Basic Plan - Build.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-build-intermediate.png`,
    },
    premium: {
      productId: "8159888179448",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNrqJnrTlLWSIiB4TRe2xF/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Build.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159880642808",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNnLJnrTlLWSIiHLIbMflm/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Build.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Intermediate,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Female Intermediate Lean',
    listName: 'Intermediate lean program plus',
    base: {
      productId: "8160022364408",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735225098488:1?channel=buy_button`,
      name: "BWS Basic Plan - Lean.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-lean-intermediate.png`,
    },
    premium: {
      productId: "8160015450360",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNrqJnrTlLWSIiB4TRe2xF/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Lean.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
    },
    elite: {
      productId: "8159958040824",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNnLJnrTlLWSIiHLIbMflm/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Lean.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Intermediate,
    goal: [Goals.BuildMuscle],
    name: 'Female Intermediate Strong',
    listName: 'Intermediate strong program plus',
    base: {
      productId: "8160120242424",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735227195640:1?channel=buy_button`,
      name: "BWS Basic Plan - Strong.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-strong-intermediate.png`,
    },
    premium: {
      productId: "8160119390456",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNrqJnrTlLWSIiB4TRe2xF/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Strong.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
    },
    elite: {
      productId: "8160117850360",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNnLJnrTlLWSIiHLIbMflm/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Strong.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Intermediate,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Male Intermediate Shred',
    listName: 'Intermediate shred program plus',
    base: {
      productId: "8159920324856",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735203373304:1?channel=buy_button`,
      name: "BWS Basic Plan - Shred.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-shred-intermediate.png`,
    },
    premium: {
      productId: "8159919407352",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNrqJnrTlLWSIiB4TRe2xF/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Shred.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159917965560",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QVNnLJnrTlLWSIiHLIbMflm/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Shred.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  }
] as const;


const LANDING_PAGE_CHOICES = _.map(APP_PROGRAM_CONFIG, (item) => item.name);

type AppProgramLevels = {
  base: AppProgramLevelProps;
  premium: AppProgramLevelProps;
  elite: AppProgramLevelProps;
}

function getStripeAppConfigForUser<T extends ProgramLevelConfig>(
  user: User,
  config: ReadonlyArray<T>
) {
  const configItem = _.find(
    config,
    (item) =>
      item.gender === user.gender &&
      _.includes(item.goal, user.goals) &&
      item.experience === user.experience 
  );
  if (!!configItem) {
    // if the user is overweight and wants to lose weight or lose fat and build muscle, we show them the beginner program
    if (user.bodytype === 'Overweight' && [Goals.LoseFat, Goals.BuildMuscleAndLoseFat].includes(user.goals)) {
      return {
        ...configItem,
        base: { 
          ...configItem.base,
        
        },
        premium: { 
          ...configItem.premium,
         
        },
        elite: {
          ...configItem.elite,
         
        }
      };
    }
  }
  return configItem;
}

const useAppProgramLevelConfigs = (user: User): AppProgramLevels & { listName: string } => {
  const configItem = getStripeAppConfigForUser<ProgramLevelConfig>(
    user,
    APP_PROGRAM_CONFIG
  );
  if (!configItem) {
    throw new Error("No configuration found for the given user");
  }
  const { base, premium, elite } = configItem;
  const mealText_config =
  configItem.name.includes("Build") || configItem.name.includes("Strong")
    ? "BUILD"
    : "SHRED";
  return {
    base: {
      ...base,
      title: "Basic",
      price: {
        current: 97,
        original: 97,
        durationWeeks: 8,
        is_annual:false,
      },
      listName: configItem.listName,
      guaranteeDurationDays: 7,
      featureHeader: "Try Out BWS",
      features: {
        intro:appIntroFeaturesBasiconly,
        basic: mealText_config.includes("BUILD")? appBaseFeatures : appBaseFeaturesShred,
        bonus: [],
        extra: [],
      },
      mobileOrder: 3,
    },
    premium: {
      ...premium,
      title: "Monthly",
      price: {
        current: 29.99,
        original: 39.99,
        durationWeeks: 16,
        is_annual:false,
      },
      listName: configItem.listName,
      guaranteeDurationDays: 14,
      featureHeader: "Fully Personalized & Guided Experience",
      features: {
        intro:[],
        basic: mealText_config.includes("BUILD")? appPremiumFeatures : appPremiumFeaturesShred,
        bonus: appPremiumBonusFeatures,
        extra: [],
      },
      mobileOrder: 2,
    },
    elite: {
      ...elite,
      title: "Annual",
      price: {
        current: 15.99,
        original: 29.99,
        durationWeeks: 32,
        is_annual:true,
      },
      listName: configItem.listName,
      headline: {
        title: "BEST DEAL",
        color: "#FFFFFF",
        background: "#406edf",
      },
      guaranteeDurationDays: 30,
      featureHeader: "Best For Fast Results & Busy Lifestyles",
      features: {
        intro: [],
        basic: mealText_config.includes("BUILD")? appEliteFeatures : appEliteFeaturesShred,
        bonus: appEliteBonusFeatures,
        extra: appEliteExtraFeatures,
      },
      mobileOrder: 1,
    },
    listName: configItem.listName,
  };
};



// split test annual vs monthly only

const appAnnualFeatures = [
  "All-In-One Fitness & Nutrition App",
  "New Workouts Based On Latest Science",
  "Effortless Nutrition Tracking With Barcode & AI Scanner",
  "Smart Coaching Every Single Workout",
  "Personalized Nutrition Guidance",
  "Offline Access",
  "24/7 Customer Support",
] as const;

const appMonthlyFeatures = [
  "All the benefits of our annual plan, without the savings.",
 
] as const;

const APP_PROGRAM_CONFIG_TEST = [
  {
    gender: Gender.Male,
    experience: Experience.Beginner,
    goal: [Goals.BuildMuscle],
    name: 'Male Beginner Build',
    listName: 'Beginner build program plus',
    base: {
      productId: "8159849545976",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735205273848:1?channel=buy_button`,
      name: "BWS Basic Plan - Build",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-build.png`,
    },
    premium: {
      productId: "8159851544824",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmFLJnrTlLWSIi6AnsJwIw/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Build",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159855902968",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmEAJnrTlLWSIirrhMwaVp/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Build",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Beginner,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Female Beginner Lean',
    listName: 'Beginner lean program plus',
    base: {
      productId: "8159927697656",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735212089592:1?channel=buy_button`,
      name: "BWS Basic Plan - Lean",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-lean.png`,
    },
    premium: {
      productId: "8159942967544",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmFLJnrTlLWSIi6AnsJwIw/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Lean",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
    },
    elite: {
      productId: "8159945556216",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmEAJnrTlLWSIirrhMwaVp/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Lean",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
    },
  },
  {
    gender: Gender.Female,
    goal: [Goals.BuildMuscle],
    experience: Experience.Beginner,
    name: "Female Beginner Strong",
    listName: "Beginner strong program plus",
    base: {
      name: "BWS Basic Plan - Strong",
      productId: "8160113131768",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-strong.png`,
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735218413816:1?channel=buy_button`,
    },
    premium: {
      name: "BWS+ Premium Plan - Strong",
      productId: "8018561990904",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmFLJnrTlLWSIi6AnsJwIw/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
    },
    elite: {
      name: "BWS+ Elite Plan - Strong",
      productId: "8018562121976",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmEAJnrTlLWSIirrhMwaVp/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Beginner,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Male Beginner Shred',
    listName: 'Beginner shred program plus',
    base: {
      productId: "8159897092344",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735185449208:1?channel=buy_button`,
      name: "BWS Basic Plan - Shred",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-shred.png`,
    },
    premium: {
      productId: "8159898042616",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmFLJnrTlLWSIi6AnsJwIw/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Shred",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159898697976",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmEAJnrTlLWSIirrhMwaVp/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Shred",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Intermediate,
    goal: [Goals.BuildMuscle],
    name: 'Male Intermediate Build',
    listName: 'Intermediate build program plus',
    base: {
      productId: "8159889096952",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735193116920:1?channel=buy_button`,
      name: "BWS Basic Plan - Build.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-build-intermediate.png`,
    },
    premium: {
      productId: "8159888179448",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmFLJnrTlLWSIi6AnsJwIw/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Build.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159880642808",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmEAJnrTlLWSIirrhMwaVp/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Build.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Intermediate,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Female Intermediate Lean',
    listName: 'Intermediate lean program plus',
    base: {
      productId: "8160022364408",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735225098488:1?channel=buy_button`,
      name: "BWS Basic Plan - Lean.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-lean-intermediate.png`,
    },
    premium: {
      productId: "8160015450360",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmFLJnrTlLWSIi6AnsJwIw/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Lean.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
    },
    elite: {
      productId: "8159958040824",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmEAJnrTlLWSIirrhMwaVp/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Lean.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Intermediate,
    goal: [Goals.BuildMuscle],
    name: 'Female Intermediate Strong',
    listName: 'Intermediate strong program plus',
    base: {
      productId: "8160120242424",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735227195640:1?channel=buy_button`,
      name: "BWS Basic Plan - Strong.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-strong-intermediate.png`,
    },
    premium: {
      productId: "8160119390456",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmFLJnrTlLWSIi6AnsJwIw/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Strong.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
    },
    elite: {
      productId: "8160117850360",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmEAJnrTlLWSIirrhMwaVp/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Strong.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Intermediate,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Male Intermediate Shred',
    listName: 'Intermediate shred program plus',
    base: {
      productId: "8159920324856",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735203373304:1?channel=buy_button`,
      name: "BWS Basic Plan - Shred.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-shred-intermediate.png`,
    },
    premium: {
      productId: "8159919407352",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmFLJnrTlLWSIi6AnsJwIw/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Premium Plan - Shred.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159917965560",
      buyLink:
        `https://api.builtwithscience.com/production/gateway/v2/stripe/create-trial/price_1QWmEAJnrTlLWSIirrhMwaVp/prod_R3V51NYeGIztoo/prod_RO8biM4NUE1Wws`,
      name: "BWS+ Elite Plan - Shred.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  }
] as const;


const LANDING_PAGE_CHOICES_TEST = _.map(APP_PROGRAM_CONFIG_TEST, (item) => item.name);



function getStripeAppConfigForUserTest<T extends ProgramLevelConfig>(
  user: User,
  config: ReadonlyArray<T>
) {
  const configItem = _.find(
    config,
    (item) =>
      item.gender === user.gender &&
      _.includes(item.goal, user.goals) &&
      item.experience === user.experience 
  );
  if (!!configItem) {
    // if the user is overweight and wants to lose weight or lose fat and build muscle, we show them the beginner program
    if (user.bodytype === 'Overweight' && [Goals.LoseFat, Goals.BuildMuscleAndLoseFat].includes(user.goals)) {
      return {
        ...configItem,
        base: { 
          ...configItem.base,
        
        },
        premium: { 
          ...configItem.premium,
         
        },
        elite: {
          ...configItem.elite,
         
        }
      };
    }
  }
  return configItem;
}

const useAppProgramLevelConfigs_test = (user: User): AppProgramLevels & { listName: string } => {
  const configItem = getStripeAppConfigForUserTest<ProgramLevelConfig>(
    user,
    APP_PROGRAM_CONFIG_TEST
  );
  if (!configItem) {
    throw new Error("No configuration found for the given user");
  }
  const { base, premium, elite } = configItem;
  const mealText_config =
  configItem.name.includes("Build") || configItem.name.includes("Strong")
    ? "BUILD"
    : "SHRED";
  return {
    base: {
      ...base,
      title: "Basic",
      price: {
        current: 97,
        original: 97,
        durationWeeks: 8,
        is_annual:false,
      },
      listName: configItem.listName,
      guaranteeDurationDays: 7,
      featureHeader: "Try Out BWS",
      features: {
        intro:appIntroFeaturesBasiconly,
        basic: mealText_config.includes("BUILD")? appBaseFeatures : appBaseFeaturesShred,
        bonus: [],
        extra: [],
      },
      mobileOrder: 3,
    },
    premium: {
      ...premium,
      title: "Monthly",
      price: {
        current: 29.99,
        original: 39.99,
        durationWeeks: 16,
        is_annual:false,
      },
      listName: configItem.listName,
      guaranteeDurationDays: 14,
      featureHeader: "Fully Personalized & Guided Experience",
      features: {
        intro:appMonthlyFeatures,
        basic: [],
        bonus: [],
        extra: [],
      },
      mobileOrder: 2,
    },
    elite: {
      ...elite,
      title: "Annual",
      price: {
        current: 15.99,
        original: 29.99,
        durationWeeks: 32,
        is_annual:true,
      },
      listName: configItem.listName,
      headline: {
        title: "BEST DEAL",
        color: "#FFFFFF",
        background: "#406edf",
      },
      guaranteeDurationDays: 30,
      featureHeader: "Best For Fast Results & Busy Lifestyles",
      features: {
        intro: appAnnualFeatures,
        basic: [],
        bonus: [],
        extra: [],
      },
      mobileOrder: 1,
    },
    listName: configItem.listName,
  };
};


export {
  getProgramNameForUser,
  useAppProgramLevelConfigs,
  LANDING_PAGE_CHOICES,
  getStripeAppConfigForUserTest,
  useAppProgramLevelConfigs_test,
  LANDING_PAGE_CHOICES_TEST,
};