import { useMemo } from "react";
import styled from "styled-components";

const PriceTextBlock = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  text-align:center;
`;

const WhiteSpaceBR = styled.div`
height:0px;
`;

const WhiteSpaceBRBase = styled.div`
color: #98a1a8;
font-style: italic;
`;

const WhitespacePremium=styled.div`
color: #98a1a8;
font-style: italic;
`;

const ProgramPriceApp = styled.div`
  font-family: "Oswald", sans-serif;
  font-size: 28px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
`;

type PriceBlockAppProps = {
  current: number;
  original: number;
  hasLimitedSupply: boolean;
  durationWeeks?: number | null;
  is_annual?:  boolean | null;
};

const PriceBlockApp = (props: PriceBlockAppProps) => {
  const { current, original, hasLimitedSupply , is_annual } = props;
  const savings = useMemo(() => original - current, [original, current]);
  const hasDiscount = savings > 0;
  
  return (
    <>
      <ProgramPriceApp>
      {hasDiscount   ?  (
          <>      
            ${current}/Month
          </>
        ) : (
          <>
            ${current}
          </>
        )}    
      </ProgramPriceApp>
      <PriceTextBlock>
      { is_annual && hasLimitedSupply === true &&
        (
          <>
        <WhitespacePremium>(billed annually)</WhitespacePremium>
        </>
        )
      }
      { is_annual===false && hasLimitedSupply === true &&

        (
          <>
        <WhitespacePremium>(billed monthly)</WhitespacePremium>
        </>
        )
      }
        {hasDiscount ? (
          <>
            {hasLimitedSupply ? (
          <></>
            ) : null}
          </>
        ) : (
          <>
            <WhiteSpaceBRBase>(billed once)</WhiteSpaceBRBase>
          </>
        )}      
   {is_annual ? (
   <>
   </> 
   ) : (
    <>
    { hasDiscount? (
      <>
      <WhiteSpaceBR></WhiteSpaceBR>
      </>
    ):(
      <>
      </>
    )}
    </>
   )}
      </PriceTextBlock>
    </>
  );
};

export type { PriceBlockAppProps };

export { PriceBlockApp };