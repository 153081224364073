enum NutritionTrackingApp {
  Yes = "Yes",
  Not_right_now="Not right now but I've used one in the past",
  No = "No",

}

const nutritionTrackingAppQuestion = {
  name: "nutritiontrackingapp",
  description: ``,
  descriptionLocation: "underTitle",
  title: `Do you currently use a nutrition tracking app?`,
  type: "radiogroup",
  choices: Object.values(NutritionTrackingApp),
  isRequired: true,
};

export { NutritionTrackingApp, nutritionTrackingAppQuestion };
