// use the amplify API to make a request against quizRestAPI /public/hubspot with the user object

import { API } from 'aws-amplify';
import { User } from '../types';

export const submitUserToHubspot = async (user: User, complete: boolean) => {
  return await API.post('quizRestAPI', '/public/hubspot/create-user', {
    body: { ...user, complete }
  });
}

export const saveTimeToGoalToHubspot = async (user: User, timeToGoal: number) => {
  const complete: boolean = true;
  return await API.post('quizRestAPI', '/public/hubspot/save-goal-time', {
    body: { ...user,complete,timeToGoal }
  });
}
