import styled from 'styled-components';

import { Card } from '../../common-ui/Card';
import { dropRight } from 'lodash';
import { PropsWithChildren, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { checkmarkStyle, landingPageListFont } from '../../common-ui/styles';

type ProgramLevelCardPropsStripe = {
  mobileOrder: number;
  
};

const ProgramLevelCardStripe = styled(Card)<ProgramLevelCardPropsStripe>`
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px 5px;
  outline: solid 5px ${({mobileOrder}) => (mobileOrder !== 1 ? 'white' : '#406edf')};
  @media (max-width: 800px) {
    order: ${({ mobileOrder }) => mobileOrder};
    padding: 10px;
    width: 100%;
  }
`;

const ProgramTitle = styled.h3`
  font-family: 'Oswald', sans-serif;
  font-size: 25px;
  color: #10181f;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const ProgramPrice = styled.div`
  font-family: 'Oswald', sans-serif;
  font-size: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
`;

const PurchaseButtonCaption = styled.span`
  color: #10181f;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 700;
  line-height: 1.1;
  padding-top: 10px;
`;

const Whitespace = styled.span`
  @media (max-width: 800px) {
    display: none;
  }
`;

type HeadlineProps = {
  color?: string;
  background?: string;
};

const Headline = styled.div<HeadlineProps>`
  position: absolute;
  transform: translateY(-100%);
  background-color: ${(props) => props.background || '#406EDF'};
  color: ${(props) => props.color};
  border-color: ${(props) => props.color};
  border: 3px solid #596dd3;
  border-image: none 100% 1 0 stretch;
  border-radius: 6px;
  font-weight: bold;
  font-size: 28px;
  padding: 5px 10px;
  border-radius: 5px;
`;

const ProgramImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

function extractFileName(path: string): string {
  const fileNameParts = dropRight(path.split('/').pop()?.split('.') || [], 1);
  return fileNameParts.join('.');
}

const DetailsButton = styled.div`
  box-sizing: border-box;
  color: #406edf;
  cursor: pointer;
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  margin-left: 0;
  margin-right: 0;
  padding: 5px 0 30px;
  text-align: center;
  display: flex;
  gap: 20px;
  justify-content: center;
`;

const DetailsContainer = styled.div`
  padding-top: 20px;
`;

const CollapsingDetails = ({ children }: PropsWithChildren) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <DetailsContainer>
      <DetailsButton onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? 'Hide Details' : 'See Details'}
        {isExpanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
      </DetailsButton>
      {isExpanded && children}
    </DetailsContainer>
  );
};

const ProgramFeature = styled.li`
  margin-bottom: 2px;
  list-style: none;
  position: relative;
  margin-left: -2em;
  padding-left: 2em;
  ${landingPageListFont}
  ${checkmarkStyle}
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &.active {
    color: rgb(64, 110, 223);
  }
`;

const BonusProgramFeature = styled(ProgramFeature)`
  color: #406edf;
`;

export type { HeadlineProps };

export {
  CollapsingDetails,
  Headline,
  ProgramFeature,
  BonusProgramFeature,
  ProgramLevelCardStripe,
  ProgramPrice,
  ProgramTitle,
  ProgramImage,
  PurchaseButtonCaption,
  Whitespace,
  extractFileName,
};
