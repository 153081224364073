import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {BlackFridayTimer} from './features/BlackFridayTimer';

import { useChartCalculations } from './charts/utils';
import { Card } from './common-ui/Card';
import { PageContainer } from './common-ui/PageContainer';
import { Section } from './common-ui/Section';
import { checkmarkStyle, commonButtonStyle, landingPageListFont } from './common-ui/styles';

import { FAQ } from './features/FAQ';
import { useAppProgramLevelConfigs , useAppProgramLevelConfigs_test} from './features/programs/Program.config';



import { Footer } from './Footer';
import { AreaBody } from './quiz/areaBody';
import { BusyLevel } from './quiz/busy';
import { Goals } from './quiz/goals';
import { Motivation } from './quiz/motivation';
import { MuscleFocus } from './quiz/muscleFocus';
import { sendViewItemListEvent } from './services/googleTagManager';
import { saveTimeToGoalToHubspot } from './services/hubspot';
import { User } from './types';
import { CDN_ROOT } from './features/images';
import { AppProgramLevel, AppProgramLevelTest, getAppProgramLevelCardId } from './features/programs/AppProgram';
import Statsig from 'statsig-js';
import Cookies from 'js-cookie';


import { useExperiment } from 'statsig-react';

const LandingPageContainer = styled(PageContainer)`
  background-color: #ffffff;
  max-width: 1200px;
  @media (max-width: 500px) {
    padding-top: 0px;
    max-width: 100%;
  }
`;

const ResultsImage = styled.img`
  width: 100%;
  max-width: 250px;
  align-self: center;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    display: none;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  max-width: 600px;
  @media (max-width: 800px) {
    min-width: 100vw;
    margin-bottom: 0px;
  }
`;

const Subtitle = styled.h2`
  font-family: 'Oswald', sans-serif;
  font-size: 40px;
  color: #1e1e1e;
  text-align: center;
  margin-bottom: 10px;
  
  
  @media (max-width: 800px) {
    font-size: 26px;
    margin-bottom: 10px;
  }
`;

const SubtitleItalic = styled.h3`
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  color: #1e1e1e;
  text-align: center;
  margin-bottom: 40px;
  font-style:italic;
  margin-top:10px;
  
  
  @media (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const PlanList = styled.ul`
  padding-inline-start: 0;
`;

const ProgramLevelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0;


  @media (max-width: 800px) {
    margin-top: 48px;
    flex-direction: column;
    gap: 50px;
    padding-left:20px;
    padding-right:20px;
  }
`;

const ProgramLevelsContainer1 = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
  gap:50px;


  @media (max-width: 800px) {
    margin-top: 48px;
    flex-direction: column;
    gap: 50px;
    padding-left:20px;
    padding-right:20px;
  }
`;

const StartNowButton = styled.button`
  ${commonButtonStyle}
`;

const RestartQuizButton = styled.button`
  text-decoration: underline;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  font-size: 16px;
`;

const PersonalPlan = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  li {
    list-style: none;
    position: relative;
    margin-left: -2em;
    padding-left: 2em;
    ${landingPageListFont}
    ${checkmarkStyle}
    margin-bottom: 10px;
  }
  ${StartNowButton} {
    margin: 0 auto;
  }
  
  @media (max-width: 800px) {
    margin-left: 30px;
    ${StartNowButton} {
      margin-left: -30px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    }
    ${Subtitle}{
    margin-left:-30px;
    }
  }
`;

const PersonalizedSection = styled(Card)`
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 5px;
  width: 90%;
  @media (max-width: 1160px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  div {
    flex: 1;
    flex-basis: 50%;

    @media (min-width: 768px) {
      min-width: 500px;
    }
  }
`;

const TileImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const NewsContainer = styled(TileImageContainer)`
  justify-content: space-around;
  @media (min-width: 767px) {
    row-gap: 30px;
  }
`;

const NewsImage = styled.img`
  min-width: 50px;
  max-width: 45%;
  height: 40px;
  margin: 0 10px;
  opacity: 0.3;

  @media (min-width: 767px) {
    width: auto;
  }
`;

const SocialsWrapper = styled(TileImageContainer)`
  margin-top:30px;
`;

const SocialLink = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 80%;
  justify-content: space-evenly;
  padding: 10px;
  margin: 10px;
  box-shadow:rgba(0, 0, 0, 0.15) 0px 5px 15px 5px;
  border-radius: 10px;
  min-width: 270px;
  @media (min-width: 768px) {
    width: auto; /* Reset width to auto on larger screens */

  }
  svg {
    margin-right: 8px;
  }
`;

const SubscriberCount = styled.span`
  font-size: 24px;
  font-weight:600;
`;


const SocialIconImage = styled.img`
  width: 100%;
  max-width: 60px;
  align-self: center;
  padding:5px;
  @media (max-width: 500px) {
    
  }
`;

const SocialText=styled.div`
  font-size:20px;
  font-family:Lato;
  font-weight:400;
`;

const TextLandingCentered= styled.p`
font-size: 20px;
color: #1E1E1E;
text-align: center;
width:100%;
max-width:1200px;
margin:0 auto;
@media (max-width: 800px) {
  line-height: 1.4;
  padding-bottom: 20px;
}
`;

const SectionMaxWidth = styled.div`
 
  width: 100%;
  max-width:1200px;
  margin:0 auto;
  @media (max-width: 800px) {
    padding-bottom:20px;
    width:90%;
  }
 
`;



const SectionTransformations = styled.section`
  margin-bottom: 20px;
  width: 100%;
  @media only screen and (max-width: 600px){
    display:none;
  }

`;
const SectionFAQ = styled.section`
  margin-bottom: 20px;
  width: 90%;
`;


const SocialIconsNew = () => {
  return (
    <>
    <Subtitle>Backed By Science – Loved By Millions</Subtitle>
    <SocialsWrapper>
      <SocialLink>
        <SocialIconImage src="https://builtwithscience.com/wp-content/uploads/2024/12/Users.png" alt="Users" />
        <SubscriberCount>200,000+<SocialText>Transformations</SocialText></SubscriberCount>
      </SocialLink>
   
      <SocialLink>
        <SocialIconImage src="https://builtwithscience.com/wp-content/uploads/2024/12/Youtube.png" alt="YouTube" />
        <SubscriberCount>6.9M+<SocialText>Youtube Subscribers</SocialText></SubscriberCount>
      </SocialLink>
      <SocialLink>
        <SocialIconImage src="https://builtwithscience.com/wp-content/uploads/2024/12/Research.png" alt="Research" />
        <SubscriberCount>$100,000+<SocialText>Funded To Research</SocialText></SubscriberCount>
       
      </SocialLink>
   
    </SocialsWrapper>
    </>
  );
};

function joinWithAnd(arr: string[]): string {
  const length = arr.length;
  if (length === 0) {
    return '';
  } else if (length === 1) {
    return arr[0];
  }
  return arr.slice(0, length - 1).join(', ') + ' and ' + arr[length - 1];
}

type UTMParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};


const createGoalAreaString = (goals: Goals, areaBody: string[], muscleFocus: string[]) => {
  const muscleText =
    muscleFocus.length === 1 && muscleFocus[0] === MuscleFocus.Equally
      ? 'across all muscle groups'
      : 'especially in the ' + joinWithAnd(muscleFocus.filter((item) => MuscleFocus.Equally !== item).map((item) => item.toLocaleLowerCase()));
  const fatLossText =
    areaBody.length === 1 && areaBody[0] === AreaBody.Equally
      ? 'evenly across your entire body'
      : 'in the ' + joinWithAnd(areaBody.filter((item) => AreaBody.Equally !== item).map((item) => item.toLocaleLowerCase()));
  switch (goals) {
    case Goals.BuildMuscleAndLoseFat:
      return `Focus on losing stubborn fat ${fatLossText} while building muscle ${muscleText}`;
    case Goals.BuildMuscle:
      return `Focus on building muscle ${muscleText}`;
    case Goals.LoseFat:
      return `Lose stubborn fat ${fatLossText}`;
  }
};

const MOTIVATION_TO_TEXT = {
  [Motivation.CONFIDENCE]: 'Feel more confident about yourself and your body',
  [Motivation.HEALTH]: 'Become healthier',
  [Motivation.FEEL_BETTER]: 'Feel better day-to-day',
  [Motivation.PROVE_CAPABLE]: `Prove to yourself that you're capable of more`,
};

type LandingPageProps = {
  user: User;
  onComplete: () => void;
  TEST_USE_DURATIONS?: boolean;
};

function get_ga_clientid() {
  const gaCookie = Cookies.get('_ga');
  if (!gaCookie) {
    return null;
  }
  return gaCookie.split('.').slice(2).join('.');
}

function getAmplitudeDeviceId() {
  return Cookies.get('bws_amplitude_device_id');
}

const LandingPage = (props: LandingPageProps) => {
  const { user, TEST_USE_DURATIONS = false } = props;
  const { chart, timeToGoal } = useChartCalculations(user);


  const experiment = useExperiment('quiz_split_test_trial');
  const experiment_version = experiment.config.value.Version;

  //const experiment_version:number = 1;
  
  console.log("experiment_version",experiment_version);
  const { base: stripeBase, premium: stripePremium, elite: stripeElite, listName: stripeListName } = useAppProgramLevelConfigs(user);
  const { base: stripeBase1, premium: stripePremium1, elite: stripeElite1, listName: stripeListName1} = useAppProgramLevelConfigs_test(user);

  const programs = useMemo(() => {

const generateUtmQueryString = () => {
  const storedParams = localStorage.getItem('utmParams');
  if (storedParams) {
    const params: UTMParams = JSON.parse(storedParams);
    const queryString = Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null && value !== '') 
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
      .join('&');

    return queryString;
  }
  return '';
};
const utm_parameters=generateUtmQueryString();

    const stableId = Statsig.getStableID();
    let stableIdParam = '';
    if (stableId != null) {
      stableIdParam = `stableId=${stableId}`;
    }
    const gaClientId = get_ga_clientid();
    let gaParam = '';
    if (gaClientId != null) {
      gaParam = `ga_id=${gaClientId}`;
    }
    const amplitudeId = getAmplitudeDeviceId();
    console.log('Amplitude Device ID: ', amplitudeId);
    let amplitudeParam = '';
    if (amplitudeId) {
      amplitudeParam = `amplitudeId=${amplitudeId}`;
    }
    const idParams = [stableIdParam, gaParam, amplitudeParam].filter((p) => p.length > 0).join('&');
    stripeBase.buyLink = `${stripeBase.buyLink}&${idParams}&${utm_parameters}`; // ? to & because for base we are using shopify checkout links
    stripePremium.buyLink = `${stripePremium.buyLink}?${idParams}&${utm_parameters}`;
    stripeElite.buyLink = `${stripeElite.buyLink}?${idParams}&${utm_parameters}`;

  return [stripeBase, stripePremium, stripeElite];
  }, [stripeBase, stripePremium, stripeElite]);


  const programs_test = useMemo(() => {

    const generateUtmQueryString = () => {
      const storedParams = localStorage.getItem('utmParams');
      if (storedParams) {
        const params: UTMParams = JSON.parse(storedParams);
        const queryString = Object.entries(params)
        .filter(([_, value]) => value !== undefined && value !== null && value !== '') 
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`) 
          .join('&');
    
        return queryString;
      }
      return '';
    };

    const utm_parameters=generateUtmQueryString();

    const stableId = Statsig.getStableID();
    let stableIdParam = '';
    if (stableId != null) {
      stableIdParam = `stableId=${stableId}`;
    }
    const gaClientId = get_ga_clientid();
    let gaParam = '';
    if (gaClientId != null) {
      gaParam = `ga_id=${gaClientId}`;
    }
    const amplitudeId = getAmplitudeDeviceId();
    console.log('Amplitude Device ID: ', amplitudeId);
    let amplitudeParam = '';
    if (amplitudeId) {
      amplitudeParam = `amplitudeId=${amplitudeId}`;
    }
    const idParams = [stableIdParam, gaParam, amplitudeParam].filter((p) => p.length > 0).join('&');
    //stripeBase1.buyLink = `${stripeBase1.buyLink}&${idParams}&${utm_parameters}`; // ? to & because for base we are using shopify checkout links
    stripePremium1.buyLink = `${stripePremium1.buyLink}?${idParams}&${utm_parameters}`;
    stripeElite1.buyLink = `${stripeElite1.buyLink}?${idParams}&${utm_parameters}`;

  return [ stripePremium1,stripeElite1];
  }, [ stripePremium1,stripeElite1]);

  const listName =  stripeListName;

  useEffect(() => {
    saveTimeToGoalToHubspot(user, timeToGoal.toDuration('months').months);
  }, [user, timeToGoal]);

  const goalAreaText = useMemo(() => createGoalAreaString(user.goals, user.areaBody, user.muscleFocus), [user.goals, user.areaBody, user.muscleFocus]);

  useEffect(() => {
    const itemEvent = {
      itemListName: listName,
      items: programs.map((item) => ({
        itemId: item.productId,
        originalPrice: item.price.original,
        price: item.price.current,
        itemName: item.name,
      })),
    };
    const itemEvent1 = {
      itemListName: listName,
      items: programs_test.map((item) => ({
        itemId: item.productId,
        originalPrice: item.price.original,
        price: item.price.current,
        itemName: item.name,
      })),
    };


    if(experiment_version === 1){
      sendViewItemListEvent(itemEvent1);
    }else{
      sendViewItemListEvent(itemEvent);
    }
    
  }, [programs,programs_test,listName, TEST_USE_DURATIONS]);

  const plan = [
    `Go from ${user.bodytype?.toLocaleLowerCase() || 'your body type'} to lean, muscular, and athletic`,
    ...(user.motivation ? [MOTIVATION_TO_TEXT[user.motivation]] : []),
    goalAreaText,
    `With customizations for your equipment${user.injuries[0] !== 'none' ? ' and injuries' : ''}`,
    `Tailored to your${[BusyLevel.FairlyBusy, BusyLevel.NoTime].includes(user.busyLevel) ? ' busy' : ''} lifestyle`,
  ];

  const handleClickScroll = () => {
    const elementId = getAppProgramLevelCardId('Annual');
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const startNowButton = <StartNowButton onClick={handleClickScroll}>
      START FREE TRIAL
    </StartNowButton>;

const programLevels = [stripeBase, stripePremium, stripeElite].map((item, i) => <AppProgramLevel key={i} {...item} />);

const programLevels1 = [  stripeElite1,stripePremium1].map((item, i) => <AppProgramLevelTest key={i} {...item} />);

const subtitle = `Choose Your Personalized Plan`;
  if(experiment_version === 1){


// Test Variant 
  

  return (
    <>
    <BlackFridayTimer/>
   <LandingPageContainer>
     <ResultsImage src="https://builtwithscience.com/wp-content/uploads/2022/12/BuiltWithScience-Blue-1.png" alt="Your Results are In" />
     <PersonalizedSection>
       <ChartWrapper>{chart}</ChartWrapper>
       <PersonalPlan>
         <Subtitle>{`${props.user.name}'s personalized program:`}</Subtitle>
         <PlanList>
           {plan
             .filter((p) => p != null)
             .map((item, i) => (
               <li key={i}>{item}</li>
             ))}
         </PlanList>
         {startNowButton}
       </PersonalPlan>
     </PersonalizedSection>
     <SectionTransformations>
       <Subtitle>Our Transformations Regularly Make Headlines</Subtitle>
       <NewsContainer>
         <NewsImage src={`${CDN_ROOT}/images/mensHealth.jpg`} alt="Men's Health" />
         <NewsImage src={`${CDN_ROOT}/images/ktla.webp`} alt="KTLA" />
         <NewsImage src={`${CDN_ROOT}/images/yahoo.jpg`} alt="Yahoo" />
         <NewsImage src={`${CDN_ROOT}/images/womensFitness.jpg`} alt="Women's Fitness" />
       </NewsContainer>
     </SectionTransformations>
     <Section id="program-levels">
       <Subtitle>{subtitle}</Subtitle>
       <SubtitleItalic>Save Up To 47% Off, Locked In For Life.</SubtitleItalic>
       <ProgramLevelsContainer1>{programLevels1}</ProgramLevelsContainer1>
     </Section>
     <SectionMaxWidth>
       <Subtitle>Unlock Your BWS+ Plan FREE For 14 Days</Subtitle>
    
       <TextLandingCentered>
       Unlock 14 days of personalized coaching with BWS+. 
       <br></br><br></br>You’ll only be charged after your trial ends, and you can cancel anytime – no hassle, no commitment.

       </TextLandingCentered>
             </SectionMaxWidth>
     {startNowButton}
     <SocialIconsNew />
     <SectionFAQ>
       <Subtitle>Frequently Asked Questions</Subtitle>
       <FAQ />
     </SectionFAQ>
    
     {startNowButton}
     <RestartQuizButton
       onClick={() => {
         localStorage.removeItem('BWS_SURVEY_DATA');
         window.location.href = '/page/1';
       }}
     >
       Restart Quiz
     </RestartQuizButton>
   </LandingPageContainer>
   <Footer />
   </>
  );


  } else{

  

  return (
    <>
    <BlackFridayTimer/>
   <LandingPageContainer>
     <ResultsImage src="https://builtwithscience.com/wp-content/uploads/2022/12/BuiltWithScience-Blue-1.png" alt="Your Results are In" />
     <PersonalizedSection>
       <ChartWrapper>{chart}</ChartWrapper>
       <PersonalPlan>
         <Subtitle>{`${props.user.name}'s personalized program:`}</Subtitle>
         <PlanList>
           {plan
             .filter((p) => p != null)
             .map((item, i) => (
               <li key={i}>{item}</li>
             ))}
         </PlanList>
         {startNowButton}
       </PersonalPlan>
     </PersonalizedSection>
     <SectionTransformations>
       <Subtitle>Our Transformations Regularly Make Headlines</Subtitle>
       <NewsContainer>
         <NewsImage src={`${CDN_ROOT}/images/mensHealth.jpg`} alt="Men's Health" />
         <NewsImage src={`${CDN_ROOT}/images/ktla.webp`} alt="KTLA" />
         <NewsImage src={`${CDN_ROOT}/images/yahoo.jpg`} alt="Yahoo" />
         <NewsImage src={`${CDN_ROOT}/images/womensFitness.jpg`} alt="Women's Fitness" />
       </NewsContainer>
     </SectionTransformations>
     <Section id="program-levels">
       <Subtitle>{subtitle}</Subtitle>
       <SubtitleItalic>Save Up To 47% Off, Locked In For Life.</SubtitleItalic>
       <ProgramLevelsContainer>{programLevels}</ProgramLevelsContainer>
     </Section>
     <SectionMaxWidth>
       <Subtitle>Unlock Your BWS+ Plan FREE For 14 Days</Subtitle>
    
       <TextLandingCentered>
       Unlock 14 days of personalized coaching with BWS+. 
       <br></br><br></br>You’ll only be charged after your trial ends, and you can cancel anytime – no hassle, no commitment.

       </TextLandingCentered>
             </SectionMaxWidth>
     {startNowButton}
     <SocialIconsNew />
     <SectionFAQ>
       <Subtitle>Frequently Asked Questions</Subtitle>
       <FAQ />
     </SectionFAQ>
    
     {startNowButton}
     <RestartQuizButton
       onClick={() => {
         localStorage.removeItem('BWS_SURVEY_DATA');
         window.location.href = '/page/1';
       }}
     >
       Restart Quiz
     </RestartQuizButton>
   </LandingPageContainer>
   <Footer />
   </>
  );

      }
        
};

export { LandingPage };