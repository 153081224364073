enum HearAboutUs {
  Youtube = 'Youtube',
  ArticleBlog = 'ArticleBlog',
  FacebookInstagram = 'FacebookInstagram',
  FriendsFamily ='FriendsFamily',
  Other='Other',
}

const hearaboutusQuestion = {
  name: 'hearaboutus',
  title: 'How did you hear about Built With Science?',
  type: 'radiogroup',
  choices: [
    {
      value: HearAboutUs.Youtube,
      text: 'Youtube',
    },
    {
      value: HearAboutUs.ArticleBlog,
      text: `Article / Blog`,
    },
    {
      value: HearAboutUs.FacebookInstagram,
      text: `Facebook / Instagram`,
    },
    {
      value: HearAboutUs.FriendsFamily,
      text: `Friends / Family`,
    },
    {
      value: HearAboutUs.Other,
      text: `Other`,
    },
  ],
  isRequired: true,
};

export {
  HearAboutUs,
  hearaboutusQuestion,
};