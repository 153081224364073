import { FunctionFactory } from 'survey-core';
import { CDN_ROOT } from '../features/images';
import { BodyFat } from "./bodyfat";
import { Gender } from './gender';
import { Goals } from './goals';

const goalBodyFatQuestion = {
  type: 'imagepicker',
  name: 'goalBodyFat',
  title: `What is the ideal body fat % you'd want to reach?`,
  description: "{goalBodyFatDescription}",
  descriptionLocation: 'underInput',
  showLabel: true,
  isRequired: true,
  colCount: 3,
};

const goalBodyFatMaleQuestion = {
  ...goalBodyFatQuestion,
  visibleIf: `{gender} = "${Gender.Male}" and {goals} = "${Goals.BuildMuscleAndLoseFat}" and {bodyFat} != "${BodyFat.Ten}"`,
  choicesVisibleIf: `{item} < {bodyFat}`,
  choices: [
    {
      value: BodyFat.Twenty,
      imageLink: `${CDN_ROOT}/images/bodyfat/goalMale20.jpg`,
      text: '20%+',
    },
    {
      value: BodyFat.Fifteen,
      imageLink: `${CDN_ROOT}/images/bodyfat/goalMale15.jpg`,
      text: '15%',
    },
    {
      value: BodyFat.Twelve,
      imageLink: `${CDN_ROOT}/images/bodyfat/goalMale12.jpg`,
      text: '12%',
    },
    {
      value: BodyFat.Ten,
      imageLink: `${CDN_ROOT}/images/bodyfat/goalMale10.jpg`,
      text: '10%',
    },
  ]
};

const goalBodyFatFemaleQuestion = {
  ...goalBodyFatQuestion,
  visibleIf: `{gender} = "${Gender.Female}" and {goals} = "${Goals.BuildMuscleAndLoseFat}" and {bodyFat} != "${BodyFat.Fifteen}"`,
  choicesVisibleIf: `{item} < {bodyFat}`,
  choices: [
    {
      value: BodyFat.TwentyFive,
      imageLink: `${CDN_ROOT}/images/bodyfat/female25.jpg`,
      text: '25%+',
    },
    {
      value: BodyFat.Twenty,
      imageLink: `${CDN_ROOT}/images/bodyfat/female20.jpg`,
      text: '20%',
    },
    {
      value: BodyFat.Eighteen,
      imageLink: `${CDN_ROOT}/images/bodyfat/female18.jpg`,
      text: '18%',
    },
    {
      value: BodyFat.Fifteen,
      imageLink: `${CDN_ROOT}/images/bodyfat/female15.jpg`,
      text: '15%',
    },
  ]
};


const goalBodyFatBothQuestion = {
  ...goalBodyFatQuestion,
  visibleIf: `{goals} = "${Goals.BuildMuscleAndLoseFat}" and {item} < {bodyFat}`,
  choicesVisibleIf: `{item} < {bodyFat}`,
  
  choices: [
    {
      value: BodyFat.Twenty,
      imageLink: `${CDN_ROOT}/images/bodyfat/goalMale20.jpg`,
      text: '20%+',
      visibleIf: `{gender} = "${Gender.Male}" and {goals} = "${Goals.BuildMuscleAndLoseFat}" and {bodyFat} != "${BodyFat.Ten}"`,
    },
    {
      value: BodyFat.Fifteen,
      imageLink: `${CDN_ROOT}/images/bodyfat/goalMale15.jpg`,
      text: '15%',
      visibleIf: `{gender} = "${Gender.Male}" and {goals} = "${Goals.BuildMuscleAndLoseFat}" and {bodyFat} != "${BodyFat.Ten}"`,
    },
    {
      value: BodyFat.Twelve,
      imageLink: `${CDN_ROOT}/images/bodyfat/goalMale12.jpg`,
      text: '12%',
      visibleIf: `{gender} = "${Gender.Male}" and {goals} = "${Goals.BuildMuscleAndLoseFat}" and {bodyFat} != "${BodyFat.Ten}"`,
    },
    {
      value: BodyFat.Ten,
      imageLink: `${CDN_ROOT}/images/bodyfat/goalMale10.jpg`,
      text: '10%',
      visibleIf: `{gender} = "${Gender.Male}" and {goals} = "${Goals.BuildMuscleAndLoseFat}" and {bodyFat} != "${BodyFat.Ten}"`,
    },
    {
      value: BodyFat.TwentyFive,
      imageLink: `${CDN_ROOT}/images/bodyfat/female25.jpg`,
      text: '25%+',
      visibleIf: `{gender} = "${Gender.Female}" and {goals} = "${Goals.BuildMuscleAndLoseFat}" and {bodyFat} != "${BodyFat.Fifteen}"`,
    },
    {
      value: BodyFat.Twenty,
      imageLink: `${CDN_ROOT}/images/bodyfat/female20.jpg`,
      text: '20%',
      visibleIf: `{gender} = "${Gender.Female}" and {goals} = "${Goals.BuildMuscleAndLoseFat}" and {bodyFat} != "${BodyFat.Fifteen}"`,
    },
    {
      value: BodyFat.Eighteen,
      imageLink: `${CDN_ROOT}/images/bodyfat/female18.jpg`,
      text: '18%',
      visibleIf: `{gender} = "${Gender.Female}" and {goals} = "${Goals.BuildMuscleAndLoseFat}" and {bodyFat} != "${BodyFat.Fifteen}"`,
    },
    {
      value: BodyFat.Fifteen,
      imageLink: `${CDN_ROOT}/images/bodyfat/female15.jpg`,
      text: '15%',
      visibleIf: `{gender} = "${Gender.Female}" and {goals} = "${Goals.BuildMuscleAndLoseFat}" and {bodyFat} != "${BodyFat.Fifteen}"`,
    },
  ]
};

const goalBodyFatDescExpression = {
  name: "goalBodyFatDescription",
  expression: "goalBodyFatDesc({goals})",
};

function goalBodyFatDescription(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as Goals) {
    case Goals.LoseFat:
      return "Using data from Built With Science, we'll predict how much weight you'll need to lose using your custom plan in order to reach your goal body fat. The results of this survey are not guaranteed and people who use Built With Science can expect to lose 0.5-1lbs per week.";
    case Goals.BuildMuscleAndLoseFat:
      return "Using data from Built With Science, we'll predict how long it will take you to reach your goal body fat if you follow your custom plan and adopt a healthy lifestyle. The results of this survey are not guaranteed and people who use Built With Science can expect to lose 0.5-1lbs per week while building muscle.";
    default:
      return "";
  }
}



FunctionFactory.Instance.register("goalBodyFatDesc", goalBodyFatDescription);

export {
  goalBodyFatMaleQuestion,
  goalBodyFatFemaleQuestion,
  goalBodyFatDescExpression,
  goalBodyFatBothQuestion,
}
