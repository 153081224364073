import { CDN_ROOT } from "../features/images";
import { Gender } from "./gender";

enum BodyType {
  Skinny = 'Skinny',
  SkinnyFat = 'Skinny Fat',
  Bulky = 'Bulky',
  Overweight = 'Overweight',
}

const bodyTypeQuestion = {
  name: "bodytype",
  title: `How would you describe your body type?`,
  description: `This will help us determine the right approach for your body`,
  descriptionLocation: 'underTitle',
  type: "imagepicker",
  showLabel: true,
  isRequired: true,
};

const bodyTypeMaleQuestion = {
  ...bodyTypeQuestion,
  visibleIf: `{gender} = "${Gender.Male}"`,
  requiredIf: `{gender} = "${Gender.Male}"`,
  choices: [
    {
      "value": BodyType.Skinny,
      "imageLink": `${CDN_ROOT}/images/bodytype/Male/skinny.png`,
      "text": "Skinny",
    },
    {
      "value": BodyType.SkinnyFat,
      "imageLink": `${CDN_ROOT}/images/bodytype/Male/skinnyfat.png`,
      "text": "Skinny Fat",
    },
    {
      "value": BodyType.Bulky,
      "imageLink": `${CDN_ROOT}/images/bodytype/Male/bulky.jpg`,
      "text": "Bulky",
    },
    {
      "value": BodyType.Overweight,
      "imageLink": `${CDN_ROOT}/images/bodytype/Male/overweight.jpg`,
      "text": "Overweight",
    },
  ]
};

const bodyTypeFemaleQuestion = {
  ...bodyTypeQuestion,
  visibleIf: `{gender} = "${Gender.Female}"`,
  requiredIf: `{gender} = "${Gender.Female}"`,
  choices: [
    {
      "value": BodyType.Skinny,
      "imageLink": `${CDN_ROOT}/images/bodytype/Female/skinny.jpg`,
      "text": "Skinny",
    },
    {
      "value": BodyType.SkinnyFat,
      "imageLink": `${CDN_ROOT}/images/bodytype/Female/skinnyfat.jpg`,
      "text": "Skinny Fat",
    },
    {
      "value": BodyType.Bulky,
      "imageLink": `${CDN_ROOT}/images/bodytype/Female/bulky.png`,
      "text": "Bulky",
    },
    {
      "value": BodyType.Overweight,
      "imageLink": `${CDN_ROOT}/images/bodytype/Female/overweight.jpg`,
      "text": "Overweight",
    },
  ]
};


const bodyTypeBothQuestion = {
  ...bodyTypeQuestion,
  
  choices: [
    {
      "value": BodyType.Skinny,
      "imageLink": `${CDN_ROOT}/images/bodytype/Male/skinny.png`,
      "text": "Skinny",
      visibleIf:`{gender} = "${Gender.Male}"`,
    },
    {
      "value": BodyType.SkinnyFat,
      "imageLink": `${CDN_ROOT}/images/bodytype/Male/skinnyfat.png`,
      "text": "Skinny Fat",
      visibleIf:`{gender} = "${Gender.Male}"`,
    },
    {
      "value": BodyType.Bulky,
      "imageLink": `${CDN_ROOT}/images/bodytype/Male/bulky.jpg`,
      "text": "Bulky",
      visibleIf:`{gender} = "${Gender.Male}"`,
    },
    {
      "value": BodyType.Overweight,
      "imageLink": `${CDN_ROOT}/images/bodytype/Male/overweight.jpg`,
      "text": "Overweight",
      visibleIf:`{gender} = "${Gender.Male}"`,
    },
    {
      "value": BodyType.Skinny,
      "imageLink": `${CDN_ROOT}/images/bodytype/Female/skinny.jpg`,
      "text": "Skinny",
      visibleIf:`{gender} = "${Gender.Female}"`,
    },
    {
      "value": BodyType.SkinnyFat,
      "imageLink": `${CDN_ROOT}/images/bodytype/Female/skinnyfat.jpg`,
      "text": "Skinny Fat",
      visibleIf:`{gender} = "${Gender.Female}"`,
    },
    {
      "value": BodyType.Bulky,
      "imageLink": `${CDN_ROOT}/images/bodytype/Female/bulky.png`,
      "text": "Bulky",
      visibleIf:`{gender} = "${Gender.Female}"`,
    },
    {
      "value": BodyType.Overweight,
      "imageLink": `${CDN_ROOT}/images/bodytype/Female/overweight.jpg`,
      "text": "Overweight",
      visibleIf:`{gender} = "${Gender.Female}"`,
    },
  ]

};

export {
  BodyType,
  bodyTypeMaleQuestion,
  bodyTypeFemaleQuestion,
  bodyTypeBothQuestion
}
