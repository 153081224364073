enum WorkoutTrackingApp {
  Yes = "Yes",
  Not_right_now="Not right now but I've used one in the past",
  No = "No",

}

const workoutTrackingAppQuestion = {
  name: "workouttrackingapp",
  description: ``,
  descriptionLocation: "underTitle",
  title: `Do you currently use a workout tracking app?`,
  type: "radiogroup",
  choices: Object.values(WorkoutTrackingApp),
  isRequired: true,
};

export { WorkoutTrackingApp, workoutTrackingAppQuestion };
