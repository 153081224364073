import Statsig from "statsig-js";

const STATSIG_CLIENT_KEY = process.env.REACT_APP_STATSIG_CLIENT_KEY || "";

const STRIPE_LINK_GATE = {
  name: "four_month_stripe_links",
} as const;

const STRIPE_EXPERIMENT_NAME = {
  name: "quiz_split_test_trial",
} as const;

const getVersionNumber = () => {
  const experiment = STRIPE_LINK_GATE;
  const config = Statsig.checkGateWithExposureLoggingDisabled(experiment.name);
  return "v 2.6.3";
};

const checkStripeGate = () => {
  return true;
};


const checkStripeExperiment = () => {
  const experiment = STRIPE_EXPERIMENT_NAME;
  
  return Statsig.getExperimentWithExposureLoggingDisabled(experiment.name);
};

export {
  STATSIG_CLIENT_KEY,
  STRIPE_LINK_GATE,
  STRIPE_EXPERIMENT_NAME,
  getVersionNumber,
  checkStripeGate,
  checkStripeExperiment,
};
