enum TypeofSupport {
  Onlyaworkout = 'Only a workout and nutrition tracking tool',
  Personalized = 'Personalized workout and nutrition coaching',
  Both =  '⁠Both a tracker and coaching',
  Other = 'Other',
}

const typeofsupportQuestion = {
  name: 'typeofsupport',
  title: 'What type of support do you need?',
  type: 'radiogroup',
  choices: [
    {
      value: TypeofSupport.Onlyaworkout,
      text: 'Only a workout and nutrition tracking tool',
    },
    {
      value: TypeofSupport.Personalized,
      text: 'Personalized workout and nutrition coaching',
    },
    {
      value: TypeofSupport.Both,
      text: '⁠Both a tracker and coaching',
    },
    {
      value: TypeofSupport.Other,
      text: 'Other',
    },
  ],
  isRequired: true,
};

export {
  TypeofSupport,
  typeofsupportQuestion,
};